/* Ensure the page-head takes up full width and height of the viewport */
.page-head {
    
    width: 100%;
    background: #F5F8FD !important;
  }
  
  .container-md {
    max-width: 1200px; /* Restrict container width */
    padding-left: 15px;
    padding-right: 15px;
  }

  .align-center {
    margin: 0 auto;
  } 
  
  .page-head .row {
    display: flex;
    flex-wrap: wrap; /* Ensure content and image wrap if necessary */
    justify-content: space-between; /* Create space between content and image */
   
  }
  
  .page-head .col-lg-6 {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .page-head .media-top42 {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .page-head .media-top42 img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    object-fit: cover; /* Ensure the image covers the area without distortion */
  }
  
  /* Styling for the text content */
  .page-head .topsub {
    font-size: 18px;
    color: #6c757d;
  }
  
  .page-head h1 {
    font-size: 36px;
    color: #333;
  }
  
  .page-head p {
    font-size: 18px;
    color: #555;
  }

  .page-head-cta {
    display: flex;
    gap: 10px;
  }
  
  .page-head-cta a {
    background-color: #FF4C4C;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    display: inline-block;
  }
  
  .page-head-cta a:hover {
    background-color: #FF4C4C;
  }
  
  .page-head .clctime ul {
    list-style-type: none;
    padding: 0;
  }
  
  .page-head .clctime ul li {
    font-size: 14px;
    color: #6c757d;
    display: inline-block !important;
    font-size: 16px !important;
    /* font-family: 'ProximaNova-Semibold' !important; */
    line-height: 26px !important;
    position: relative !important;
  }
  .clctime ul li:not(:first-child)::before {
    display: inline-block;
    padding-right: 0.8rem;
    padding-left: 0.8rem;
    color: #6c757d;
    content: "|";
}
  /* Responsive Design */
  @media (max-width: 768px) {
    .page-head {
      flex-direction: column; /* Stack the content and image vertically */
    }
    .page-head .col-lg-6 {
      width: 100%; /* Ensure columns take full width */
    }
    .page-head .media-top42 img {
      width: 100%; /* Ensure image takes full width */
    }
  }
  