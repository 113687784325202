/* General container for the Privacy Policy */
.privacy-policy-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 30px;
    background-color: #ffffff; /* white background */
    color: #333; /* dark text color */
    line-height: 1.7;
    text-align: left;
  }
  
  /* Header Section */
  .privacy-policy-header {
    text-align: center;
    margin-bottom: 50px;
  }
  
  .privacy-policy-header h1 {
    font-size: 2.5rem;
    color: #333; /* Blue color for the main heading */
    font-weight: bold;
  }
  
  .privacy-policy-header p {
    font-size: 1rem;
    color: #666; /* Slightly lighter color for the paragraph */
    text-align: left;
  }
  
  /* Content Section (Main sections) */
  .privacy-policy-content h2 {
    font-size: 1.8rem;
    color: #333; /* Blue color for sub-headings */
    margin-top: 30px;
    margin-bottom: 15px;
    font-weight: bold;
  }
  
  .privacy-policy-content p {
    font-size: 1rem;
    color: #555; /* Text color for content */
    margin-bottom: 20px;
  }
  
  .privacy-policy-content ul {
    list-style-type: disc;
    font-size: 1rem;
    color: #555;
  }
  
  .privacy-policy-content li {
    margin-bottom: 5px;
  }
  
  /* Buttons & Links Styling */
  .privacy-policy-footer {
    margin-top: 40px;
    text-align: center;
  }
  
  .privacy-policy-footer label {
    font-size: 1rem;
    color: #333;
  }

  .left-align {
    text-align: left;
  }
  
  .privacy-policy-footer input[type="checkbox"] {
    margin-right: 10px;
  }
  
  .privacy-policy-footer .accept-btn {
    background-color: #b30000; /* Blue button */
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1.1rem;
    cursor: pointer;
    border-radius: 4px;
    margin-top: 15px;
    transition: background-color 0.3s ease;
  }
  
  .privacy-policy-footer .accept-btn:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .privacy-policy-footer .accept-btn:hover:enabled {
    background-color: #084a8f; /* Darker blue when hovered */
  }
  
  /* Links (like Learn More, etc.) */
  .privacy-policy-content a {
    color: #b30000; /* Blue color for links */
    text-decoration: none;
  }
  
  .privacy-policy-content a:hover {
    text-decoration: underline;
  }
  .privacy_policy_table th,.privacy_policy_table td{
    padding: 15px;
  }
  .policy_details span{
    margin-right: 4px;
  }

  /* styles.css */

.privacy_policy_table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-family: Arial, sans-serif;
}

.privacy_policy_table th,
.privacy_policy_table td {
  padding: 12px 15px;
  text-align: left;
  border: 1px solid #ddd;
}

.privacy_policy_table th {
  background-color: #f4f4f4;
  color: #333;
}

.privacy_policy_table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.privacy_policy_table td {
  font-size: 14px;
  color: #555;
}


  .inner_points_policy li{list-style-type: none;}
  /* Responsive Design */
  @media screen and (max-width: 768px) {
    .privacy-policy-container {
      padding: 20px;
    }
  
    .privacy-policy-header h1 {
      font-size: 2rem;
    }
  
    .privacy-policy-content h2 {
      font-size: 1.6rem;
    }
  
    .privacy-policy-content p {
      font-size: 0.95rem;
    }
  }
  