/* General styling for the stats page */
.innerpage {
    background-color: #f9f9f9;
    padding: 50px 0;
  }
  
  .innerpage-bg {
    background-color: #f4f4f4;
  }
  
  .row-gap-24 {
    gap: 24px;
  }
  
  .h-inerbox {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .h-inerimg img {
    width: 62px;
    height: 61px;
  }
  
  .h-inertxt {
    margin-left: 20px;
  }
  
  .h-inertxt h2 {
    font-size: 24px;
    margin-bottom: 0;
    font-weight: bold;
  }
  
  .h-inertxt h4 {
    font-size: 18px;
    color: #888;
    margin-bottom: 8px;
    margin-top: 5px;
  }
  
  .h-inertxt p {
    font-size: 14px;
    color: #666;
    margin-top: 10px;
  }
  