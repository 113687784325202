/* General reset for the container */
.container {
    max-width: 1218px;
    margin: 0 auto;
    padding: 0 15px;
  }
  
  /* Section styling */
  .innerpage {
    padding: 60px 0;
    background-color: #f9f9f9;
    position: relative;
  }
  
  .inn-textly {
    padding-right: 30px;
  }
  
  /* Flexbox layout to align image and text side by side */
  .row {
    display: flex;
    justify-content: center;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-right: -15px;
        margin-left: -15px;
  }
  *, ::after, ::before {
    box-sizing: border-box;
}
.col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 15px;
    padding-left: 15px;
}
  .col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
    padding-right: 15px;
    padding-left: 15px;
}
 
  
  /* Title styling */
  .flex-title {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }
  
  .flex-titleimg {
    margin-right: 15px;
  }
  
  .flex-titletxt h2 {
    font-size: 28px;
    font-weight: 700;
    color: #333;
    margin: 0;
  }
  
  .Publishing-icon {
    font-size: 28px;
    font-weight: 600;
    color: #2c3e50;
  }
  
  /* Paragraph styling */
  p {
    font-size: 16px;
    color: #555;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .mb-40 {
    margin-bottom: 40px;
  }
  
  /* Learn More link */
  .readmoreicon {
    color: #FF4C4C;
    text-decoration: none;
    font-weight: 500;
  }
  
  .readmoreicon:hover {
    text-decoration: underline;
  }
  
  /* List styling */
  .row-cl {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .cllist {
    display: flex;
    flex-direction: column;
  }
  
  .cllist .title {
    font-weight: 700;
    color: #333;
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .cllist p {
    font-size: 14px;
    color: #777;
    margin-bottom: 0;
    margin-top: 0;
  }
  .justify-content-center{
    justify-content: center;
  }
  
  /* Image container */
  .inn-img {
    text-align: center;
    margin-top: 40px;
  }
  
  .inn-img img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }
  
  /* Review section styling */
  .g2-review {
    margin-top: 30px;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .gr-nmbox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .gr-nm p {
    font-weight: 700;
    color: #333;
  }
  
  .g2-star img {
    max-width: 120px;
    height: auto;
  }
  
  .gr-rew p {
    font-size: 14px;
    color: #555;
    line-height: 1.5;
    font-style: italic;
  }
  
  /* Banner section */
  .inn-btmct {
    margin-top: 50px;
    text-align: center;
  }
  
  .innbtm-banr {
    background-color: #FF4C4C;
    color: white;
    padding: 30px;
    border-radius: 8px;
  }
  
  .innbtm-banrtxt h4 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  
  .innbtm-banr p {
    font-size: 16px;
    margin-bottom: 30px;
  }
  
  .innbtm-banrcta .btn-blue {
    background-color: #FF4C4C;
    color: white;
    padding: 12px 30px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 50px;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }
  
  .innbtm-banrcta .btn-blue:hover {
    background-color: #004080;
  }
  
  /* Responsive styles */
  @media (max-width: 992px) {
    .row {
      flex-direction: column;
      gap: 40px;
    }
  
    .flex-title {
      text-align: center;
    }
  
    .inn-textly {
      padding-right: 0;
    }
  
    .inn-img {
      margin-top: 20px;
    }
  }
  
  @media (max-width: 768px) {
    .inn-img img {
      max-width: 90%;
    }
  }
  