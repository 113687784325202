body {
  margin: 0;
  font-family: "Source Sans 3", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* CSS for the page */
.innerpage-bg {
  background-color: #fff !important;
  padding: 50px 0;
}

.flex-title {
  display: flex;
  align-items: center;
}

.flex-titleimg img {
  width: 48px;
  height: 48px;
}

.flex-titletxt h2 {
  font-size: 28px;
  margin-left: 15px;
  font-weight: bold;
}

.row-cl .cllist {
  margin-bottom: 0;
}

.cllist .title {
  font-weight: bold;
}

.inn-img img {
  max-width: 100%;
  height: auto;
}

.g2-review .gr-nmbox {
  display: flex;
  align-items: center;
}

.g2-review .gr-nm p {
  font-weight: bold;
}

.g2-review .gr-rew p {
  font-size: 14px;
}

.innbtm-banr {
  background-color: #E94647;
  padding: 40px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.innbtm-banr p {
  color: #fff;
}

.innbtm-banrcta a {
  background-color: #00bfff;
  padding: 10px 20px;
  color: white;
  font-size: 16px;
  text-decoration: none;
  border-radius: 5px;
}


