/* Footer Container */
.page-template-page-new .footer-container {
  background-color: #E94647;
  border-top: 1px solid #ddd;
}

.page-template-page-new .footer-container .container {
  max-width: 1140px;
  margin: 0 auto;
}

.page-template-page-new .footer-container .custom-footer-other-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.page-template-page-new .footer-container .footer-title {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 16px;
  color: #333;
}

.page-template-page-new .footer-container .footer-container nav ul {
  padding: 0;
  list-style: none;
}

.page-template-page-new .footer-container .footer-container nav ul li {
  margin-bottom: 10px;
}

.page-template-page-new .footer-container .footer-container nav ul li a {
  color: #555;
  text-decoration: none;
  font-size: 0.95rem;
  transition: color 0.3s ease;
}

/* .page-template-page-new .footer-container .footer-container nav ul li a:hover {
  color: #FF4C4C;
} */

/* Responsive Columns */
.page-template-page-new .footer-container .custom-footer-other-links .col {
  width: 48%;
}

.page-template-page-new .footer-container .custom-footer-other-links .col-w50 {
  width: 48%;
}

@media (max-width: 768px) {
  .page-template-page-new .footer-container .custom-footer-other-links .col-w50 {
    width: 100%;
    margin-bottom: 20px;
  }
}

/* Footer Bottom Section */
.page-template-page-new .footer-lstliun {
  background-color: #E94647;
  width: 100%;
  color: #fff;
  padding: 20px 0;
}

.page-template-page-new .footer-lstliun .container-md {
  max-width: 1140px;
  margin: 0 auto;
}

.page-template-page-new .footer-lstliun .cpy-txt ul {
  display: flex;
  justify-content: flex-start;
  padding-left: 0;
  list-style: none;
}

.page-template-page-new .footer-lstliun .cpy-txt ul li {
  margin-right: 20px;
}

.page-template-page-new .footer-lstliun .cpy-txt ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 0.875rem;
}

.page-template-page-new .footer-lstliun .spl-txt .followlist {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-template-page-new .footer-lstliun .spl-txt .followlist li {
  margin-right: 15px;
}

.page-template-page-new .footer-lstliun .spl-txt .followlist .tt-line {
  font-size: 0.875rem;
  font-weight: bold;
  margin-right: 10px;
}

.page-template-page-new .footer-lstliun .spl-txt .followlist li a img {
  width: 28px;
  height: 28px;
}

/* Additional Styling for Social Media Icons */
.page-template-page-new .footer-lstliun .spl-txt .followlist li a {
  display: inline-block;
  transition: transform 0.2s ease;
}

.page-template-page-new .footer-lstliun .spl-txt .followlist li a:hover {
  transform: scale(1.1);
}

.page-template-page-new .footer-container {
  background-color: #f8f8f8;
}

.footer-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.custom-footer-other-links .col-w50 {
  width: 48%;
  display: inline-block;
  padding: 10px;
}

.custom-footer-other-links ul {
  list-style: none;
  padding: 0;
}

.custom-footer-other-links li {
  margin-bottom: 5px;
}

.followlist {
  display: flex;
  align-items: center;
}

.followlist li {
  margin-right: 10px;
}

