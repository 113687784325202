.innerpage {
  background: #F4F9FF !important;
  }

  .g2-header {
    display: flex;
    justify-content: center;
  }

  .pg-title48 {
    text-align: center;
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 40px;
  }
  
  .g2-reg {
    padding: 20px;
    border-radius: 8px;
  }
  
  .gr-retg {
    font-size: 2rem;
    font-weight: bold;
    margin: 0;
  }
  
  .g2-star img {
    max-width: 100%;
  }
  
  .row-gap-24 {
    gap: 24px;
  }
  
  .row-media991-center {
    justify-content: center;
  }
  
  .img-fluid {
    max-width: 100%;
    height: auto;
  }
  
  .mediah-991 {
    max-width: 900px;
    margin: 0 auto;
  }
  