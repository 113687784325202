    .terms-container {
        margin: 0 auto;
        max-width: 1200px;
        padding: 20px;
        color: #333;
    }

    .terms-container a {
        color: #b30000;
        text-decoration: none;
      }
    
    .terms-title {
        text-align: center;
        color: #333;
        font-size: 2.5rem;
        margin-bottom: 20px;
    }
    
    .terms-section {
        margin-bottom: 20px;
    }
    
    .terms-section h2 {
        font-size: 1.8rem;
        color: #333; /* Deep Red */
        margin-top: 20px;
    }
    
    .terms-section p {
        font-size: 1rem;
        line-height: 1.6;
    }
    
    .terms-section ul {
        list-style-type: disc;
        padding-left: 20px;
    }
    
    footer {
        text-align: center;
        padding: 20px;
        font-size: 0.9rem;
        color: #777;
        background-color: #f7f7f7;
    }
    
    footer p {
        margin: 0;
    }
    