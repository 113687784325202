/* General Styles */

  .innerpage {
    padding-bottom: 30px;
  }
  
  .innerpage-bg {
    background-color: #f7f7f7;
    padding-top: 40px;
  }
  
  .pg-title h2 {
    font-size: 36px;
    color: #333;
    text-align: center;
    margin-bottom: 0;
    margin-top: 0;
  }
  
  /* Client Logos Section */
  .silderMedia32 {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .show-web img {
    width: 100%;
    max-width: 942px;
    height: auto;
  }
  
  .show-mob {
    display: none;
  }
  
  .customer-logos .slick-track {
    display: flex;
    justify-content: space-around;
  }
  
  .customer-logos .slick-slide img {
    max-width: 120px;
    height: auto;
  }
  
  /* Slick Slider responsive */
  @media (max-width: 768px) {
    .show-web {
      display: none;
    }
  
    .show-mob {
      display: block;
    }
  }
  
  /* Features Section */
  .row-gap-30 {
    margin-top: 40px;
  }
  
  .btterxbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
    background: #F5F8FD !important;
  }
  
  .btterximg {
    width: 58px;
    margin-right: 20px;
  }
  
  .btterxtxt h3 {
    font-size: 24px;
    color: #333;
    margin-bottom: 10px;
  }
  
  .btterxtxt p {
    min-height: 77px;
    font-size: 16px;
    color: #666;
  }
  
  /* Responsive Design */
  @media (max-width: 991px) {
    .btterxbox {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  
  @media (max-width: 520px) {
    .pg-title h2 {
      font-size: 28px;
    }
  
    .btterxbox {
      padding: 15px;
    }
  
    .btterxtxt h3 {
      font-size: 20px;
    }
  
    .btterxtxt p {
      font-size: 14px;
    }
  }
  