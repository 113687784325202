/* General styles for the tool section */
.section-hm-bd {
    padding: 80px 0;
    background-color: #f9f9f9;
  }
  
  .pg-title {
    margin-bottom: 0;
  }
  
  .pg-title h2 {
    font-size: 32px;
    font-weight: bold;
  }
  
  .pg-title p {
    font-size: 18px;
    color: #777;
    margin-top: 0;
  }
  
  .pt-80 {
    padding-top: 80px;
  }

  .pt-40 {
    padding-top: 40px;
  }
  
  .bd-box {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 30px;
    text-align: center;
  }
  
  .bd-boximg img {
    width: 100%;
    max-width: 200px;
    margin-bottom: 20px;
  }
  
  .bd-box h3 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .bd-box ul {
    list-style-type: none;
    padding: 0;
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .bd-box ul li {
    margin-bottom: 10px;
    color: #555;
    text-align: left;
  }
  
  .bd-box p {
    margin-top: 20px;
  }
  
  .readmoreicon {
    color: #FF4C4C;
    text-decoration: none;
    font-weight: bold;
  }
  
  .readmoreicon:hover {
    text-decoration: underline;
  }
  