/* Header Container */
.header-container {
    border-bottom: 1px solid #ddd;
    background-color: #fff;
    padding: 10px 0;
  }
  
  .header-container .container {
    max-width: 1200px;
    margin: 0 auto;
  }
  .ml-auto{
    margin-left: auto;
  }
  .sp_clentlogos {
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    max-width: 100%;
    padding: 80px 0;
    
}

  .header-container .row {
    display: flex;
    align-items: center;
  }
  
  .custom-logo {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .custom-logo a img {
    max-width: 100%;
  }
  
  .media-center {
    text-align: center;
  }
  
  .media-hide {
    display: block;
  }
  
  .web-hide {
    display: none;
  }
  
  .web-trial-btn {
    background-color: #1c87d7;
    color: #fff;
    padding: 8px 20px;
    text-decoration: none;
    font-weight: bold;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .web-trial-btn:hover {
    background-color: #155a8a;
  }
  
  /* Navbar */
  nav ul {
    display: flex;
    justify-content: flex-end;
    padding-left: 0;
    list-style: none;
  }
  
  nav ul li {
    position: relative;
    margin-left: 8px;
  }
  
  nav ul li a {
    font-size: 16px;
    color: #333;
    text-decoration: none;
    font-weight: 500;
    padding: 10px 15px;
    display: block;
    transition: color 0.3s;
  }
  
  nav ul li a:hover {
    color: #E94647;
  }
  
  /* Mega Menu */
  .mega-drop-down {
    position: relative;
  }
  
  .mega-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    padding: 20px;
    z-index: 999;
  }
  
  .mega-menu-wrap {
    display: flex;
    justify-content: space-between;
  }
  
  .mega-menu-wrap .menu-col {
    flex: 1;
  }
  
  .mega-menu-wrap ul {
    list-style: none;
    padding-left: 0;
  }
  
  .mega-menu-wrap ul li {
    margin-bottom: 10px;
  }
  
  .mega-menu-wrap ul li a {
    color: #333;
    text-decoration: none;
    font-weight: 500;
    padding: 8px 0;
    display: block;
    transition: color 0.3s;
  }
  
  .mega-menu-wrap ul li a:hover {
    color: #1c87d7;
  }
  
  .mega-drop-down:hover .mega-menu {
    display: block;
  }
  
  .all_feat {
    margin-top: 20px;
  }
  
  .all_feat a {
    font-weight: 600;
    color: #1c87d7;
  }
  
  .all_feat a:hover {
    text-decoration: underline;
  }
  
  /* Menu for small screens */
  .toggle-menu {
    display: none;
  }
  
  @media screen and (max-width: 991px) {
    nav ul {
      display: none;
      flex-direction: column;
      padding-top: 10px;
    }
  
    nav ul li {
      margin-left: 0;
      text-align: center;
    }
  
    .toggle-menu {
      display: block;
      cursor: pointer;
      font-size: 24px;
      padding: 10px;
    }
  
    .toggle-menu.visible-xs-block {
      display: block;
    }
  
    .media-down {
      display: none;
    }
  
    .mega-menu {
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      box-shadow: none;
    }
  
    nav ul li:hover .mega-menu {
      display: none;
    }
  
    nav ul.show {
      display: flex;
    }
  }
  
  @media screen and (max-width: 767px) {
    .header-container {
      padding: 15px 0;
    }
  
    .web-trial-btn {
      display: block;
      margin: 10px auto;
    }
  }
  